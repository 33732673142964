import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '../enums/locales.enum';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from './urls.service';

interface ITranslateProp {
  [x: string]: string | ITranslateProp;
}

export interface ITranslation {
  [locales.RU]: ITranslateProp;
  // [locales.EN]: ITranslateProp;
  [locales.UK]: ITranslateProp;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationHelperService {
  constructor(private _http: HttpClient, private _translateService: TranslateService) {
    this._http
      .get(UrlsService.getTranslationUrl('base'))
      .toPromise()
      .then((translation: ITranslation) => this.setTranslation(translation));
  }

  public setTranslation(translation: ITranslation): void {
    this._translateService.setTranslation(locales.RU, translation[locales.RU], true);
    this._translateService.setTranslation(locales.UK, translation[locales.UK], true);
    // this._translateService.setTranslation(locales.EN, translation[locales.EN], true);
  }
}
