import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../services/content.service';
import { EventsContent } from '../../models/events-content';

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent {
  @Input() showHeader: boolean = false;

  showSchedule: boolean = false;
  schedule: string = '';

  content$: Observable<EventsContent> = this._contentService.getContent<EventsContent>('events', EventsContent);

  constructor(private _contentService: ContentService) {}

  toggleSchedule(schedule: string = ''): void {
    this.schedule = schedule;
    this.showSchedule = !this.showSchedule;
  }
}
