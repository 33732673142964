import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'ext-info',
  templateUrl: './ext-info.component.html',
  styleUrls: ['./ext-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtInfoComponent implements OnChanges {
  @ViewChild('contentEl') readonly contentEl: ElementRef;

  @Input() showInfo: boolean = false;

  ngOnChanges(): void {
    if (this.showInfo) {
      this.scrollToContent();
    }
  }

  scrollToContent(): void {
    setTimeout(() => {
      window.scrollTo({
        top: this.contentEl.nativeElement.getBoundingClientRect().top + window.pageYOffset - 120,
        behavior: 'smooth',
      });
    }, 0);
  }
}
