import { Injector } from '@angular/core';
import { StylesService } from '../services/styles.service';
import { ChapterInfo, IChapterInfoDto } from './chapter-info';

export interface ICommonContent {
  chapterInfo: ChapterInfo;
  bgColor: string;
  containerClasses: string;
}

export interface ICommonContentDto extends IChapterInfoDto {
  bg_color: string;
}

export class CommonContent implements ICommonContent {
  readonly chapterInfo!: ChapterInfo;
  readonly bgColor: string;
  readonly containerClasses: string;

  constructor(protected options: ICommonContentDto, protected injector: Injector) {
    this.bgColor = this.injector.get(StylesService).getColor(options.bg_color);
    this.containerClasses = options.container_classes || '';
    this.chapterInfo = new ChapterInfo(options, this.injector);
  }
}
