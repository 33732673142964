import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonContent } from '../../../../../../models/common-content';
import { M1025, M576, M768 } from '../../../../../../consts/window';
import { ContentService } from '../../../../../../services/content.service';
import { GalleryBriefService } from '../../services/gallery-brief.service';
import { IAlbumDto } from '../../models/interfaces';

@Component({
  selector: 'gallery-brief',
  templateUrl: './gallery-brief.component.html',
  providers: [GalleryBriefService],
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class GalleryBriefComponent {
  imgs: string[] = [];
  albums: string[] = [];
  slidesCount: number = 0;

  imgs$: Observable<string[]> = this._galleryBriefService.getAlbums().pipe(
    map((albums: IAlbumDto[]) => {
      const IMGS = [];
      const IMGS_URL = '../../../assets/img/photos/items/';

      albums?.forEach((album: IAlbumDto) => IMGS.push(...[0, 1].map((photo: number) => `${IMGS_URL}${album.id}/small/${photo}.jpg`)));

      return IMGS;
    })
  );
  content$: Observable<CommonContent> = this._contentService.getContent<CommonContent>('gallery_brief', CommonContent);

  constructor(private _contentService: ContentService, private _galleryBriefService: GalleryBriefService) {
    this.setSlideSCount();
  }

  onResize(): void {
    this.setSlideSCount();
  }

  setSlideSCount(): void {
    const windowWidth = Math.min(window.outerWidth, window.innerWidth);

    if (windowWidth < M576) {
      this.slidesCount = 2;
    } else if (windowWidth < M768) {
      this.slidesCount = 3;
    } else if (windowWidth < M1025) {
      this.slidesCount = 4;
    } else {
      this.slidesCount = 5;
    }
  }
}
