import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../../../models/common-content';
import { CommonContentWithImg } from '../../../../../models/common-content-with-img';
import { StylesService } from '../../../../../services/styles.service';

export interface IReasonToChoiseItemDto extends ICommonContentDto {
  desc_color: string;
  desc_bg_color: string;
  border_color: string;
}

export class ReasonToChoiseItem extends CommonContentWithImg {
  readonly img!: string;
  readonly borderColor!: string;

  constructor(options: IReasonToChoiseItemDto, protected injector: Injector) {
    super(options, injector);

    this.img = `../assets/img/${options.id.replace(/\./g, '/')}.png`;
    this.borderColor = this.injector.get(StylesService).getColor(options.border_color);
  }
}
