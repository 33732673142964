import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContentService } from '../../../../services/content.service';
import { ProgramItem } from '../../models/program-item';
import { ProgramsContent } from '../../models/programs-content';

@Component({
  selector: 'schedule-programs',
  templateUrl: './schedule-programs.component.html',
})
export class ScheduleProgramsComponent {
  selectedProgram: ProgramItem = null;

  content$: Observable<ProgramsContent> = this._contentService.getContent<ProgramsContent>('programs', ProgramsContent).pipe(
    tap((content: ProgramsContent) => {
      const PROGRAM_ID: string = this._route.snapshot.params.id;
      this.selectedProgram = content.items.find((item: ProgramItem) => item.id === PROGRAM_ID);
    })
  );

  constructor(private _contentService: ContentService, private _route: ActivatedRoute) {}

  showExtContent(program: ProgramItem): void {
    this.selectedProgram = program;
  }
}
