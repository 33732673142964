import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../models/common-content';
import { CommonContentWithImg } from '../../../models/common-content-with-img';

export class ParentPageItem extends CommonContentWithImg {
  readonly id!: string;
  readonly img!: string;

  constructor(options: ICommonContentDto, protected injector: Injector) {
    super(options, injector);

    this.id = options.id;
    this.img = `../../../../assets/img/${this.id.replace('.', '/')}.png`;
  }
}
