import { Injector } from '@angular/core';
import { CommonContent, ICommonContentDto } from '../../../../../models/common-content';
import { StylesService } from '../../../../../services/styles.service';

interface IDiplomasContentDto extends ICommonContentDto {
  diplomas_count: number;
  controls_color: string;
}

export class DiplomasContent extends CommonContent {
  readonly diplomasCount: number;
  readonly controlsColor: string;

  constructor(options: IDiplomasContentDto, protected injector: Injector) {
    super(options, injector);

    this.diplomasCount = options.diplomas_count;
    this.controlsColor = this.injector.get(StylesService).getColor(options.controls_color);
  }
}
