import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IMethodologyOriginalItemDto, MethodologyOriginalItem } from './methodology-original-item';

export interface IMethodologyOriginalsContentDto {
  header_color: string;
  items: IMethodologyOriginalItemDto[];
}

export class MethodologyOriginalsContent {
  readonly headerColor!: string;
  readonly img!: string;
  readonly items!: MethodologyOriginalItem[];

  readonly #chapterName!: string;

  constructor(options: IMethodologyOriginalsContentDto & { chapterName: string }, protected injector: Injector) {
    this.headerColor = options.header_color;
    this.#chapterName = options.chapterName;
    this.img = `../../../../../assets/img/${this.#chapterName}/originals.png`;
    this.items = options.items.map(
      (item: IMethodologyOriginalItemDto) => new MethodologyOriginalItem({ chapterName: options.chapterName, ...item }, injector)
    );
  }

  get header(): string {
    return this.injector.get(TranslateService).instant(`${this.#chapterName}.originals.header`);
  }
}
