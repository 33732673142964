import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateCompiler, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
import { locales } from './enums/locales.enum';
import { NavMenuModule } from './shared/nav-menu/nav-menu.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { AboutPageModule } from './pages/about-page/about-page.module';
import { ContactsPageModule } from './pages/contacts-page/contacts-page.module';
import { MainPageModule } from './pages/main-page/main-page.module';
import { MethodologyPageModule } from './pages/methodology-page/methodology-page.module';
import { ParentsPageModule } from './pages/parents-page/parents-page.module';
import { PhotosPageModule } from './pages/photos-page/photos-page.module';
import { ProgramsPageModule } from './pages/programs-page/programs-page.module';
import { RootComponent } from './root/root.component';

@NgModule({
  declarations: [RootComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedComponentsModule,
    NavMenuModule,
    MainPageModule,
    AboutPageModule,
    MethodologyPageModule,
    ProgramsPageModule,
    ParentsPageModule,
    PhotosPageModule,
    ContactsPageModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  providers: [],
  bootstrap: [RootComponent],
})
export class AppModule {
  constructor(public translateService: TranslateService) {
    this.translateService.addLangs([<string>locales.UK]);
    this.translateService.setDefaultLang(<string>locales.UK);
    this.translateService.use(<string>locales.UK);
  }
}
