import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../models/common-content';
import { CommonContentWithImg } from '../../../models/common-content-with-img';
import { StylesService } from '../../../services/styles.service';

export interface INavMenuItemDto extends ICommonContentDto {
  active_header_color: string;
}

export class NavMenuItem extends CommonContentWithImg {
  readonly img!: string;
  readonly route!: string;
  readonly activeHeaderColor!: string;

  constructor(options: INavMenuItemDto, protected injector: Injector) {
    super(options, injector);

    this.route = `/${options.id.split('.').pop()}`;
    this.img = `../assets/img/${options.id.replace(/\./g, '/')}.png`;
    this.activeHeaderColor = this.injector.get(StylesService).getColor(options.active_header_color);
  }
}
