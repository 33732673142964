import { Injector } from '@angular/core';
import { IWorkingMethodologyItemDto, WorkingMethodologyItem } from './working-methodology-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../../../models/common-content-with-items';

export class WorkingMethodologyContent extends CommonContentWithItems<WorkingMethodologyItem> {
  readonly items!: WorkingMethodologyItem[];

  constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: IWorkingMethodologyItemDto) => new WorkingMethodologyItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
