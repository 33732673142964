import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

const PHONE_REGEXP = /(\d\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/;
const PHONE_PATTERN = '$1 ($2) $3 $4 $5';

@Component({
  selector: 'phone',
  templateUrl: './phone.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneComponent implements OnInit {
  @Input() text: string = '';
  @Input() phone: string = '';

  formattedPhone: string = '';

  ngOnInit(): void {
    this.formattedPhone = this.phone.replace(PHONE_REGEXP, PHONE_PATTERN);
  }
}
