import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DIRECTIONS, TRANSITIONS } from './enums';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
    '(window:blur)': 'onBlur()',
    '(window:focus)': 'onFocus()',
  },
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('slidesContainer', { static: true })
  readonly slidesContainerEl: ElementRef;
  @ViewChild('carouselSlides', { static: true })
  readonly carouselSlidesEl: ElementRef;

  @Input() slides: any[] = [];
  @Input() tmplOutlet: any = null;
  @Input() slidesCount = 1;
  @Input() height = '250px';
  @Input() activeSlideIndex = 0;
  @Input() controlColor = 'white';

  @Output() readonly slideClick: EventEmitter<number> = new EventEmitter();

  items: any[] = [];
  sliderContainerWidth = 0;
  transition: string = TRANSITIONS.NOT_ANIMATED;

  private lazyImgs: HTMLImageElement[] = [];
  private canShift = true;
  private timeout = null;
  private currentDirection = DIRECTIONS.RIGHT;

  constructor(private _renderer: Renderer2) {}

  get slidesWidth(): string {
    return `${this.sliderContainerWidth * this.items.length}px`;
  }

  get slideWidth(): string {
    return `${this.sliderContainerWidth / this.slidesCount}px`;
  }

  get shift(): string {
    return `translate3d(-${(this.activeSlideIndex * this.sliderContainerWidth) / this.slidesCount}px, 0px, 0px)`;
  }

  ngOnInit(): void {
    this.activeSlideIndex += this.slidesCount;
    this.#buildItems();
    this.#setSliderContainerWidth();
    this.startAutoSliding();
  }

  ngAfterViewInit(): void {
    this.lazyImgs = this.carouselSlidesEl.nativeElement.querySelectorAll('.lazy') || [];
    for (let i = this.activeSlideIndex; i < this.activeSlideIndex + this.slidesCount; i++) {
      this.loadImg(i);
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  loadImg(index: number = this.currentDirection === DIRECTIONS.RIGHT ? this.activeSlideIndex + this.slidesCount - 1 : this.activeSlideIndex): void {
    clearTimeout(this.timeout);

    const LAZY_IMG = this.lazyImgs[index];
    if (LAZY_IMG?.classList.contains('lazy')) {
      const IMAGE = this._renderer.createElement('img');
      this._renderer.setAttribute(IMAGE, 'src', this.items[index].img);

      IMAGE.onload = () => {
        setTimeout(() => {
          this._renderer.setAttribute(LAZY_IMG, 'src', this.items[index].img);
          this._renderer.removeClass(LAZY_IMG, 'lazy');

          LAZY_IMG.parentElement.style.backgroundImage = 'none';

          this.startAutoSliding();
        }, 300);
      };
    } else {
      this.startAutoSliding();
    }
  }

  public startAutoSliding(): void {
    this.canShift = true;
    this.timeout = setTimeout(() => this.slideToRight(), 5000);
  }

  public stopAutoSliding(): void {
    this.canShift = false;
    clearTimeout(this.timeout);
  }

  onResize(): void {
    this.#setSliderContainerWidth();
  }

  onBlur(): void {
    clearTimeout(this.timeout);
  }

  onFocus(): void {
    this.startAutoSliding();
  }

  slideToRight(): void {
    if (this.canShift) {
      this.currentDirection = DIRECTIONS.RIGHT;
      this.#doSlide();
      this.activeSlideIndex++;
    }
  }

  slideToLeft(): void {
    if (this.canShift) {
      this.currentDirection = DIRECTIONS.LEFT;
      this.#doSlide();
      this.activeSlideIndex--;
    }
  }

  onTransitionEnd(): void {
    this.transition = TRANSITIONS.NOT_ANIMATED;
    setTimeout(() => {
      if (this.activeSlideIndex === this.slidesCount - 1) {
        this.activeSlideIndex = this.items.length - (this.slidesCount + 1);
        for (let i = this.activeSlideIndex + this.slidesCount; i >= this.activeSlideIndex; i--) {
          this.loadImg(i);
        }
      } else if (this.activeSlideIndex === this.items.length - this.slidesCount) {
        this.activeSlideIndex = this.slidesCount;
        this.loadImg();
      } else {
        this.loadImg();
      }
    }, 0);
  }

  onSlideClick(event: MouseEvent, index: number): void {
    event.stopPropagation();
    this.slideClick.emit(index - this.slidesCount);
  }

  #buildItems(): void {
    for (let i = this.slidesCount; i > 0; i--) {
      this.items.push(this.slides[this.slides.length - i]);
    }

    Array.prototype.push.apply(this.items, this.slides);

    for (let i = 0; i < this.slidesCount; i++) {
      this.items.push(this.slides[i]);
    }
  }

  #doSlide(): void {
    clearTimeout(this.timeout);
    this.canShift = false;
    this.transition = TRANSITIONS.ANIMATED;
  }

  #setSliderContainerWidth(): void {
    this.sliderContainerWidth = this.slidesContainerEl.nativeElement.offsetWidth;
  }
}
