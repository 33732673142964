import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { AboutInfoContent } from '../../models/about-info-content';

@Component({
  selector: 'about-info',
  templateUrl: './about-info.component.html',
})
export class AboutInfoComponent {
  content$: Observable<AboutInfoContent> = this._contentService.getContent<AboutInfoContent>('about_info', AboutInfoContent);

  constructor(private _contentService: ContentService) {}
}
