import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['scroll-top.component.scss'],
  host: {
    '(window:scroll)': 'onScroll()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollTopComponent {
  showBtn: boolean = false;

  scrollTop(e: Event): void {
    e.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  onScroll(): void {
    this.showBtn = window.pageYOffset > 200;
  }
}
