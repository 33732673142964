export class DiplomasService {
  static readonly CHAPTER_NAME: string = 'diplomas';

  static getImg(index: number): string {
    return `assets/img/${DiplomasService.CHAPTER_NAME}/${index}.jpg`;
  }

  static getSmallImg(index: number): string {
    return `assets/img/${DiplomasService.CHAPTER_NAME}/small/${index}.jpg`;
  }
}
