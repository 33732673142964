import { Injector } from '@angular/core';
import { IPhotosPageItemDto, PhotosPageContentItem } from './photos-page-content-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';

export class PhotosPageContent extends CommonContentWithItems<PhotosPageContentItem> {
  readonly items!: PhotosPageContentItem[];

  constructor(options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: IPhotosPageItemDto) => new PhotosPageContentItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
