import { Injector } from '@angular/core';
import { ChapterInfo, IParagraphClasses } from '../../../models/chapter-info';
import { ICommonContentDto } from '../../../models/common-content';
import { StylesService } from '../../../services/styles.service';

export interface IProgramItemDto extends ICommonContentDto {
  content_color: string;
  header_classes: string;
  paragraphs_classes: IParagraphClasses;
  age_color: string;
  age_bg_color: string;
  age: string;
  age_interval: string;
  duration: string;
  price: string;
  border_color: string;
  schedule_border_color: string;
  program_header_color: string;
  program_content_color: string;
  program_bg_color: string;
  program_link_color: string;
  program_link_bg_color: string;
  program_link_classes: string;
  photo_link_color: string;
  photo_link_bg_color: string;
  photo_link_classes: string;
  ext_info: {
    paragraphs_classes: IParagraphClasses;
    container_classes: string;
  };
  sign_up_link: string;
}

export class ProgramItem {
  readonly id!: string;
  readonly photosLink!: string;
  readonly chapterName: string = 'programs';
  readonly img!: string;
  readonly programImg!: string;
  readonly age!: string;
  readonly ageInterval!: string;
  readonly duration!: string;
  readonly price!: string;
  readonly borderColor!: string;
  readonly scheduleBorderColor!: string;
  readonly ageColor!: string;
  readonly ageBgColor!: string;
  readonly chapterInfo!: ChapterInfo;
  readonly extInfo!: ChapterInfo;
  readonly programBgColor!: string;
  readonly programLink!: ChapterInfo;
  readonly photoLink!: ChapterInfo;
  readonly signUpLink!: string;

  constructor(options: IProgramItemDto, protected _injector: Injector) {
    this.id = options.id;
    this.photosLink = `/photos/${this.id}`;
    this.img = `assets/img/${this.chapterName}/${this.id}.png`;
    this.programImg = `assets/img/${this.chapterName}/${this.id}_ext.png`;
    this.age = options.age;
    this.ageInterval = options.age_interval;
    this.duration = options.duration;
    this.price = options.price;
    this.borderColor = this._injector.get(StylesService).getColor(options.border_color);
    this.scheduleBorderColor = this._injector.get(StylesService).getColor(options.schedule_border_color);
    this.ageColor = this._injector.get(StylesService).getColor(options.age_color);
    this.ageBgColor = this._injector.get(StylesService).getColor(options.age_bg_color);
    this.programBgColor = this._injector.get(StylesService).getColor(options.program_bg_color);

    this.programLink = new ChapterInfo(
      {
        id: `${this.chapterName}.items.${this.id}.programLink`,
        header_color: options.program_link_color,
        header_bg_color: options.program_link_bg_color,
        header_suffix: ` ${this.age}`,
        header_classes: options.program_link_classes,
      },
      this._injector
    );

    this.photoLink = new ChapterInfo(
      {
        id: `${this.chapterName}.items.${this.id}.photoLink`,
        header_color: options.photo_link_color,
        header_bg_color: options.photo_link_bg_color,
        header_classes: options.photo_link_classes,
      },
      this._injector
    );

    this.chapterInfo = new ChapterInfo(
      {
        id: `${this.chapterName}.items.${this.id}`,
        header_color: options.header_color,
        header_suffix: ` ${this.age}`,
        header_classes: options.header_classes,
        paragraphs_classes: options.paragraphs_classes,
        content_color: options.content_color,
      },
      _injector
    );

    this.extInfo = new ChapterInfo(
      {
        id: `${this.chapterName}.items.${this.id}.ext_info`,
        header_color: options.program_header_color,
        header_suffix: ` ${this.age}`,
        paragraphs_classes: options.ext_info.paragraphs_classes,
        container_classes: options.ext_info.container_classes,
        content_color: options.program_content_color,
      },
      _injector
    );

    this.signUpLink = options.sign_up_link || '#';
  }
}
