import { Injector } from '@angular/core';
import { IReasonToChoiseItemDto, ReasonToChoiseItem } from './reason-to-choise-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../../../models/common-content-with-items';

export class ReasonToChoiseContent extends CommonContentWithItems<ReasonToChoiseItem> {
  readonly items!: ReasonToChoiseItem[];

  constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: IReasonToChoiseItemDto) => new ReasonToChoiseItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
