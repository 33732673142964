import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../services/content.service';
import { NavMenuContent } from '../../models/nav-menu-content';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
  content$: Observable<NavMenuContent> = this._contentService.getContent<NavMenuContent>('menu', NavMenuContent);

  constructor(private _contentService: ContentService) {}
}
