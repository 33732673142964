import { Injector } from '@angular/core';
import { CommonContentWithImg } from '../../../models/common-content-with-img';
import { ICommonContentDto } from '../../../models/common-content';

export interface IMethodologyItemDto extends ICommonContentDto {
  has_img: boolean;
}

export class MethodologyContentItem extends CommonContentWithImg {
  readonly hasImg: boolean;
  readonly img!: string;

  constructor(options: IMethodologyItemDto, protected injector: Injector) {
    super(options, injector);

    this.hasImg = options.has_img;
    this.img = `../../../assets/img/${options.id.replace(/\./g, '/')}.png`;
  }
}
