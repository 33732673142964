import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { M576 } from '../../../../../../consts/window';
import { ContentService } from '../../../../../../services/content.service';
import { AdvertisementsContent } from '../../models/advertisements-content';

@Component({
  selector: 'advertisements',
  templateUrl: './advertisements.component.html',
  styleUrls: ['./advertisements.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class AdvertisementsComponent {
  carouselHeight: string = '';

  content$: Observable<AdvertisementsContent> = this._contentService
    .getContent<AdvertisementsContent>('advs', AdvertisementsContent)
    .pipe(tap(() => this.onResize()));

  constructor(private _contentService: ContentService) {}

  onResize(): void {
    this.carouselHeight = Math.min(window.outerWidth, window.innerWidth) >= M576 ? '350px' : '550px';
  }
}
