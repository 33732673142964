import { Component, Input } from '@angular/core';
import { ProgramItem } from '../../models/program-item';

@Component({
  selector: 'program-item',
  templateUrl: './program-item.component.html',
  styleUrls: ['./program-item.component.scss'],
})
export class ProgramItemComponent {
  @Input() program!: ProgramItem;
}
