import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParentsPageComponent } from './components/parents-page/parents-page.component';
import { EventsModule } from '../../shared/events/events.module';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';

@NgModule({
  declarations: [ParentsPageComponent],
  imports: [CommonModule, SharedComponentsModule, EventsModule],
  exports: [ParentsPageComponent],
})
export class ParentsPageModule {}
