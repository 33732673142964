import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MethodologyPageComponent } from './components/methodology-page/methodology-page.component';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ChapterWrapper } from './components/chapter-wrapper/chapter-wrapper.component';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';

@NgModule({
  declarations: [MethodologyPageComponent, ChapterWrapper],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [MethodologyPageComponent],
})
export class MethodologyPageModule {}
