import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { ReasonToChoiseContent } from '../../models/reason-to-choise-content';

@Component({
  selector: 'reason-to-choise',
  templateUrl: './reason-to-choise.component.html',
})
export class ReasonToChoiseComponent {
  content$: Observable<ReasonToChoiseContent> = this._contentService.getContent<ReasonToChoiseContent>('reason_to_choise', ReasonToChoiseContent);

  constructor(private _contentService: ContentService) {}
}
