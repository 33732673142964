import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export interface IMethodologyOriginalItemDto {
  id: string;
  link: string;
  color: string;
}

export class MethodologyOriginalItem {
  readonly link!: string;
  readonly color!: string;

  readonly #chapterName: string = '';
  readonly #id: string = '';

  constructor(options: IMethodologyOriginalItemDto & { chapterName: string }, protected injector: Injector) {
    this.link = options.link;
    this.color = options.color;

    this.#id = options.id;
    this.#chapterName = options.chapterName;
  }

  get name(): string {
    return this.injector.get(TranslateService).instant(`${this.#chapterName}.originals.items.${this.#id}`);
  }
}
