import { Injector } from '@angular/core';
import { EventItem } from './event-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';
import { ICommonContentDto } from '../../../models/common-content';

export class EventsContent extends CommonContentWithItems<EventItem> {
  readonly items!: EventItem[];

  constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: ICommonContentDto) => new EventItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
