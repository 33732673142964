import { Component, Input } from '@angular/core';
import { ProgramItem } from '../../models/program-item';

@Component({
  selector: 'program-info',
  templateUrl: './program-info.component.html',
})
export class ProgramInfoComponent {
  @Input() program!: ProgramItem;
}
