import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainPageComponent } from './components/main-page/main-page.component';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ContactsPageModule } from '../contacts-page/contacts-page.module';
import { GalleryBriefModule } from './modules/gallery-brief/gallery-brief.module';
import { AdvertisementsModule } from './modules/advertisements/advertisements.module';
import { GreetingModule } from './modules/greeting/greeting.module';
import { SecretOfSuccessModule } from './modules/secret-of-success/secret-of-success.module';
import { ProgramsModule } from '../../shared/programs/programs.module';
import { StatisticsModule } from './modules/statistics/statistics.module';
import { EventsModule } from '../../shared/events/events.module';

@NgModule({
  declarations: [MainPageComponent],
  imports: [
    CommonModule,
    AdvertisementsModule,
    GreetingModule,
    SecretOfSuccessModule,
    ProgramsModule,
    GalleryBriefModule,
    EventsModule,
    StatisticsModule,
    ContactsPageModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [MainPageComponent],
})
export class MainPageModule {}
