import { Injector } from '@angular/core';
import { CommonContent, ICommonContentDto } from '../../../../../models/common-content';
import { StylesService } from '../../../../../services/styles.service';

export interface IStatisticsItemDto extends ICommonContentDto {
  count: number;
  count_color: string;
}

export class StatisticsItem extends CommonContent {
  readonly count!: number;
  readonly countColor!: string;

  constructor(options: IStatisticsItemDto, protected injector: Injector) {
    super(options, injector);

    this.count = options.count;
    this.countColor = this.injector.get(StylesService).getColor(options.count_color);
  }
}
