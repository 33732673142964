import { Component, Input } from '@angular/core';
import { ReasonToChoiseItem } from '../../models/reason-to-choise-item';

@Component({
  selector: 'reason-to-choise-item',
  templateUrl: './reason-to-choise-item.component.html',
  styleUrls: ['./reason-to-choise-item.component.scss'],
})
export class ReasonToChoiseItemComponent {
  @Input() item!: ReasonToChoiseItem;
}
