import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';
import { AdvertisementsComponent } from './components/advertisements/advertisements.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AdvertisementsComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [AdvertisementsComponent],
})
export class AdvertisementsModule {}
