import { Injector } from '@angular/core';
import { IMethodologyItemDto, MethodologyContentItem } from './methodology-content-item';
import { IMethodologyOriginalsContentDto, MethodologyOriginalsContent } from './methodology-originals-content';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';
import { StylesService } from '../../../services/styles.service';

export interface IMethodologyContentDto extends ICommonContentWithItemsDto {
  content_color: string;
  bg_color: string;
  chapter_border_color: string;
  chapter_icon_color: string;
  items: IMethodologyItemDto[];
  originals: IMethodologyOriginalsContentDto;
}

export class MethodologyContent extends CommonContentWithItems<MethodologyContentItem> {
  readonly items!: MethodologyContentItem[];
  readonly chapterBorderColor!: string;
  readonly chapterIconColor!: string;
  readonly originals!: MethodologyOriginalsContent;

  constructor(options: IMethodologyContentDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: IMethodologyItemDto) => new MethodologyContentItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );

    this.originals = new MethodologyOriginalsContent({ chapterName: options.id, ...options.originals }, injector);

    this.chapterBorderColor = this.injector.get(StylesService).getColor(options.chapter_border_color);
    this.chapterIconColor = this.injector.get(StylesService).getColor(options.chapter_icon_color);
  }
}
