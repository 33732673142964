import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { GreetingContent } from '../../models/greeting-content';

@Component({
  selector: 'greeting',
  templateUrl: './greeting.component.html',
})
export class GreetingComponent {
  content$: Observable<GreetingContent> = this._contentService.getContent<GreetingContent>('greeting', GreetingContent);

  constructor(private _contentService: ContentService) {}
}
