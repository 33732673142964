import { Injector } from '@angular/core';
import { INavMenuItemDto, NavMenuItem } from './nav-menu-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';

export class NavMenuContent extends CommonContentWithItems<NavMenuItem> {
  readonly items!: NavMenuItem[];

  constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: INavMenuItemDto) => new NavMenuItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
