import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonContent } from '../../../../../../models/common-content';
import { WorkingMethodologyItem } from '../../models/working-methodology-item';

@Component({
  selector: 'working-methodology-item',
  templateUrl: './working-methodology-item.component.html',
  styleUrls: ['./working-methodology-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingMethodologyItemComponent {
  @Input() item!: WorkingMethodologyItem;

  @Output() readonly extInfo: EventEmitter<CommonContent> = new EventEmitter();

  showExtInfo(): void {
    this.extInfo.emit(this.item.extInfo);
  }
}
