import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlsService } from './urls.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface IColors {
  [x: string]: string;
}

@Injectable({
  providedIn: 'root',
})
export class StylesService {
  private colors: IColors = null;

  constructor(private _http: HttpClient) {}

  setColors(): Observable<IColors> {
    return this._http.get<IColors>(UrlsService.getDbUrl('colors')).pipe(tap((colors: IColors) => (this.colors = colors)));
  }

  public getColor(colorCode: string): string {
    return this.colors[colorCode] || colorCode;
  }
}
