import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { SharedComponentsModule } from '../components/shared-components.module';
import { EventItemComponent } from './components/event-item/event-item.component';
import { EventsComponent } from './components/events/events.component';

@NgModule({
  declarations: [EventsComponent, EventItemComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [EventsComponent],
})
export class EventsModule {}
