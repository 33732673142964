import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlsService } from '../../../../../services/urls.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAlbumDto } from '../models/interfaces';
import { ICommonContentWithItems } from '../../../../../models/common-content-with-items';

@Injectable()
export class GalleryBriefService {
  constructor(private _http: HttpClient) {}

  public getAlbums(): Observable<IAlbumDto[]> {
    return this._http.get(UrlsService.getDbUrl('photos')).pipe(map((content: ICommonContentWithItems<IAlbumDto>) => content.items));
  }
}
