import { Injector } from '@angular/core';
import { ChapterInfo } from '../../../models/chapter-info';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';

import { IProgramItemDto, ProgramItem } from './program-item';

interface IProgramsContentDto extends ICommonContentWithItemsDto<IProgramItemDto> {
  header_brief_color: string;
  header_brief_classes: string;
}

export class ProgramsContent extends CommonContentWithItems<ProgramItem> {
  readonly items!: ProgramItem[];
  readonly headerBrief!: ChapterInfo;

  constructor(protected options: IProgramsContentDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map((item: IProgramItemDto) => new ProgramItem(item, this.injector));

    this.headerBrief = new ChapterInfo(
      {
        id: `${options.id}.header_brief`,
        header_color: options.header_brief_color,
        header_classes: options.header_brief_classes,
      },
      this.injector
    );
  }
}
