import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../services/content.service';
import { PhotosPageContent } from '../../models/photos-page-content';

@Component({
  selector: 'photos-page',
  templateUrl: './photos-page.component.html',
})
export class PhotosPageComponent {
  content$: Observable<PhotosPageContent> = this._contentService.getContent<PhotosPageContent>('photos', PhotosPageContent);

  constructor(private _contentService: ContentService) {}
}
