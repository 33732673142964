import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'request-call',
  templateUrl: './request-call.component.html',
  styleUrls: ['./request-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestCallComponent {
  isPopupShown: boolean = false;
}
