import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { SharedComponentsModule } from '../components/shared-components.module';
import { ProgramInfoComponent } from './components/program-info/program-info.component';
import { ProgramItemComponent } from './components/program-item/program-item.component';
import { ProgramsComponent } from './components/programs/programs.component';
import { ScheduleProgramItemComponent } from './components/schedule-program-item/schedule-program-item.component';
import { ScheduleProgramsComponent } from './components/schedule-programs/schedule-programs.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [ProgramsComponent, ScheduleProgramsComponent, ProgramItemComponent, ScheduleProgramItemComponent, ProgramInfoComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [ProgramsComponent, ScheduleProgramsComponent],
})
export class ProgramsModule {}
