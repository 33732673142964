import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { CacheService } from './cache.service';
import { ITranslation, TranslationHelperService } from './translation-helper.service';
import { UrlsService } from './urls.service';

const CHAPTER_NAME = 'request_call';

@Injectable()
export class RequestCallService {
  constructor(private _http: HttpClient, private _translateService: TranslateService, private _translationHelperService: TranslationHelperService) {}

  getContent(): Observable<void> {
    return CacheService.cache[CHAPTER_NAME]
      ? of(CacheService.cache[CHAPTER_NAME])
      : this._http.get(UrlsService.getTranslationUrl(CHAPTER_NAME)).pipe(
          tap((translations: ITranslation) => {
            this._translationHelperService.setTranslation(translations);
          }),
          share()
        );
  }

  public requestCall(name: string, phone: string): Observable<string> {
    const url = 'https://pochemuchka-server.herokuapp.com/email';
    const lang = this._translateService.currentLang;

    return this._http.post<string>(url, {
      name,
      phone,
      lang,
    });
  }
}
