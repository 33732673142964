import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsComponent } from './components/contacts/contacts.component';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ContactsPageComponent } from './components/contacts-page/contacts-page.component';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';

@NgModule({
  declarations: [ContactsPageComponent, ContactsComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [ContactsPageComponent, ContactsComponent],
})
export class ContactsPageModule {}
