import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { M576 } from '../../../../../consts/window';
import { ContentService } from '../../../../../services/content.service';
import { FeedbacksContent } from '../models/feedbacks-content';

@Component({
  selector: 'feedbacks',
  templateUrl: './feedbacks.component.html',
  styleUrls: ['./feedbacks.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class FeedbacksComponent {
  carouselHeight: string = '';

  content$: Observable<FeedbacksContent> = this._contentService
    .getContent<FeedbacksContent>('feedbacks', FeedbacksContent)
    .pipe(tap(() => this.onResize()));

  constructor(private _contentService: ContentService) {}

  onResize(): void {
    this.carouselHeight = Math.min(window.outerWidth, window.innerWidth) >= M576 ? '300px' : '550px';
  }
}
