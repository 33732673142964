import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramsPageComponent } from './components/programs-page/programs-page.component';
import { ProgramsModule } from '../../shared/programs/programs.module';

@NgModule({
  declarations: [ProgramsPageComponent],
  imports: [CommonModule, ProgramsModule],
  exports: [ProgramsPageComponent],
})
export class ProgramsPageModule {}
