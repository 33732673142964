import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { RequestCallService } from '../../../services/request-call.service';

@Component({
  selector: 'request-call-modal',
  templateUrl: './request-call-modal.component.html',
  styleUrls: ['./request-call-modal.component.scss'],
  providers: [RequestCallService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestCallModalComponent {
  @Output() readonly closePopup: EventEmitter<void> = new EventEmitter<void>();

  popupContent: Observable<void> = this._requestCallService.getContent();
  response: string;
  isPending: boolean = false;
  requestCallForm = new FormGroup({
    userName: new FormControl('', [Validators.required, Validators.pattern(/^\D{2,30}$/)]),
    userPhone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]),
  });

  get userName(): AbstractControl {
    return this.requestCallForm.get('userName');
  }

  get userPhone(): AbstractControl {
    return this.requestCallForm.get('userPhone');
  }

  constructor(private _requestCallService: RequestCallService) {}

  requestCall(): void {
    if (this.isPending || this.requestCallForm.invalid) {
      return;
    }

    this.isPending = true;

    this._requestCallService.requestCall(this.userName.value, this.userPhone.value).subscribe(
      (response: string) => (this.response = response),
      () => (this.isPending = false)
    );
  }
}
