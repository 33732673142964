import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { WorkingMethodologyItemComponent } from './components/working-methodology-item/working-methodology-item.component';
import { WorkingMethodologyComponent } from './components/working-methodology/working-methodology.component';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';

@NgModule({
  declarations: [WorkingMethodologyComponent, WorkingMethodologyItemComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [WorkingMethodologyComponent],
})
export class WorkingMethodologyModule {}
