import { Component } from '@angular/core';
import { StylesService } from '../services/styles.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { IContacts } from '../pages/contacts-page/models/interfaces';
import { ContactsService } from '../pages/contacts-page/services/contacts.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent {
  stylesLoaded = this._stylesService.setColors();
  requisitesContent: Observable<IContacts> = this._contactsService.getContent();

  constructor(private router: Router, private _stylesService: StylesService, private _contactsService: ContactsService) {
    this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => window.scrollTo(0, 0));
  }
}
