import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonContent } from '../../../../../../models/common-content';
import { ContentService } from '../../../../../../services/content.service';
import { WorkingMethodologyContent } from '../../models/working-methodology-content';

@Component({
  selector: 'working-methodology',
  templateUrl: './working-methodology.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkingMethodologyComponent {
  selectedExtInfo: CommonContent = null;

  content$: Observable<WorkingMethodologyContent> = this._contentService.getContent<WorkingMethodologyContent>(
    'working_methodology',
    WorkingMethodologyContent
  );

  constructor(private _contentService: ContentService) {}

  showExtInfo(extInfo: CommonContent): void {
    this.selectedExtInfo = extInfo;
  }
}
