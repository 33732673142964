import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EventItem } from '../../models/event-item';

@Component({
  selector: 'event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventItemComponent {
  @Input() item!: EventItem;

  @Output() readonly scheduleClick: EventEmitter<string> = new EventEmitter();
}
