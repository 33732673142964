import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../services/content.service';
import { ProgramsContent } from '../../models/programs-content';

@Component({
  selector: 'programs',
  templateUrl: './programs.component.html',
})
export class ProgramsComponent {
  content$: Observable<ProgramsContent> = this._contentService.getContent<ProgramsContent>('programs', ProgramsContent);

  constructor(private _contentService: ContentService) {}
}
