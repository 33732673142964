import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatisticsItem } from '../../models/statistics-item';

@Component({
  selector: 'statistics-item',
  templateUrl: './statistics-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsItemComponent {
  @Input() item!: StatisticsItem;
}
