import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../services/content.service';
import { MethodologyContent } from '../../models/methodology-content';

@Component({
  selector: 'methodology-page',
  templateUrl: './methodology-page.component.html',
  styleUrls: ['./methodology-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MethodologyPageComponent {
  content$: Observable<MethodologyContent> = this._contentService.getContent<MethodologyContent>('methodology', MethodologyContent);

  constructor(private _contentService: ContentService) {}
}
