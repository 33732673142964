import { Injector } from '@angular/core';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../../../models/common-content-with-items';
import { ChapterInfo } from '../../../../../models/chapter-info';
import { ICommonContentDto } from '../../../../../models/common-content';

export class FeedbacksContent extends CommonContentWithItems<ChapterInfo> {
  readonly items!: ChapterInfo[];

  constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: ICommonContentDto) => new ChapterInfo({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
