import { Component, Input } from '@angular/core';

@Component({
  selector: 'chapter-wrapper',
  templateUrl: './chapter-wrapper.component.html',
  styleUrls: ['./chapter-wrapper.component.scss'],
})
export class ChapterWrapper {
  @Input() borderColor: string = 'black';
  @Input() iconColor: string = 'black';
  @Input() isOdd: boolean = false;
}
