import { Injector } from '@angular/core';
import { CommonContent, ICommonContentDto } from '../../../../../models/common-content';
import { CommonContentWithImg } from '../../../../../models/common-content-with-img';
import { StylesService } from '../../../../../services/styles.service';

export interface IWorkingMethodologyItemDto extends ICommonContentDto {
  header_bg_color: string;
  ext_info: ICommonContentDto;
  border_color: string;
}

export class WorkingMethodologyItem extends CommonContentWithImg {
  readonly img!: string;
  readonly borderColor!: string;
  readonly extInfo!: CommonContent;

  constructor(options: IWorkingMethodologyItemDto, protected injector: Injector) {
    super(options, injector);

    this.borderColor = this.injector.get(StylesService).getColor(options.border_color);
    this.img = `../assets/img/${options.id.replace(/\./g, '/')}.png`;
    this.extInfo = new CommonContent({ ...options.ext_info, ...{ id: `${options.id}.ext_info` } }, injector);
  }
}
