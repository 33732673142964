import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutPageComponent } from './pages/about-page/components/about-page/about-page.component';
import { ContactsPageComponent } from './pages/contacts-page/components/contacts-page/contacts-page.component';
import { MainPageComponent } from './pages/main-page/components/main-page/main-page.component';
import { MethodologyPageComponent } from './pages/methodology-page/components/methodology-page/methodology-page.component';
import { ProgramsPageComponent } from './pages/programs-page/components/programs-page/programs-page.component';
import { ParentsPageComponent } from './pages/parents-page/components/parents-page/parents-page.component';
import { PhotosPageComponent } from './pages/photos-page/components/photos-page/photos-page.component';
import { PhotosComponent } from './pages/photos-page/components/photos/photos.component';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'main',
    pathMatch: 'full',
  },
  {
    path: 'main',
    component: MainPageComponent,
  },
  {
    path: 'about',
    component: AboutPageComponent,
    loadChildren: () => import('./pages/about-page/about-page.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'methodology',
    component: MethodologyPageComponent,
    loadChildren: () => import('./pages/methodology-page/methodology-page.module').then((m) => m.MethodologyPageModule),
  },
  {
    path: 'children',
    component: ProgramsPageComponent,
  },
  {
    path: 'parents',
    component: ParentsPageComponent,
  },
  {
    path: 'contacts',
    component: ContactsPageComponent,
  },
  {
    path: 'photos',
    children: [
      {
        path: '',
        component: PhotosPageComponent,
      },
      {
        path: ':id',
        component: PhotosComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'main',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: true, // ,
      // enableTracing: true // <-- debugging purposes only
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
