import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StylesService } from '../services/styles.service';

export interface IChapterInfo {
  id: string;
  header: string;
  headerClasses: string;
  paragraphs: string[];
  paragraphsClasses: IParagraphClasses;
  headerColor: string;
  contentColor: string;
}

export interface IChapterInfoDto {
  id: string;
  header_color: string;
  header_bg_color?: string;
  header_suffix?: string;
  header_classes?: string;
  content_color?: string;
  content_bg_color?: string;
  paragraphs_classes?: IParagraphClasses;
  container_classes?: string;
}

export interface IParagraphClasses {
  [x: string]: string;
}

export class ChapterInfo implements IChapterInfo {
  readonly id!: string;
  readonly headerColor: string;
  readonly headerBgColor: string;
  readonly headerClasses: string;
  readonly contentColor: string;
  readonly contentBgColor: string;
  readonly paragraphsClasses: IParagraphClasses;
  readonly containerClasses: string;

  readonly #headerSuffix: string;

  constructor(options: IChapterInfoDto, private _injector: Injector) {
    this.id = options.id;
    this.headerColor = this._injector.get(StylesService).getColor(options.header_color);
    this.headerBgColor = this._injector.get(StylesService).getColor(options.header_bg_color);
    this.contentColor = this._injector.get(StylesService).getColor(options.content_color);
    this.contentBgColor = this._injector.get(StylesService).getColor(options.content_bg_color);
    this.headerClasses = options.header_classes || '';
    this.paragraphsClasses = options.paragraphs_classes || {};
    this.containerClasses = options.container_classes || '';
    this.#headerSuffix = options.header_suffix || '';
  }

  get header(): string {
    return `${this._injector.get(TranslateService).instant(`${this.id}.header`)}${this.#headerSuffix}`;
  }

  get paragraphs(): string[] {
    const PARAGRAPHS = this._injector.get(TranslateService).instant(`${this.id}.paragraphs`);
    return typeof PARAGRAPHS === 'object' ? Object.keys(PARAGRAPHS).map((item) => PARAGRAPHS[item]()) : [];
  }
}
