import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IContacts } from '../../../../pages/contacts-page/models/interfaces';
import { ContactsService } from '../../../../pages/contacts-page/services/contacts.service';
import { NavMenuItem } from '../../models/nav-menu-item';

@Component({
  selector: 'mobile-menu',
  templateUrl: 'mobile-menu.component.html',
  styleUrls: ['mobile-menu.component.scss'],
})
export class MobileMenuComponent {
  @Input() items!: NavMenuItem;

  active: string = '';

  requisitesContent$: Observable<IContacts> = this._contactsService.getContent();

  constructor(private _contactsService: ContactsService) {}

  toggleMenu(): void {
    setTimeout(() => {
      this.active = this.active === '' ? 'active' : '';
    }, 0);
  }
}
