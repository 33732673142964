import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatisticsItemComponent } from './components/statistics-item/statistics-item.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';

@NgModule({
  declarations: [StatisticsComponent, StatisticsItemComponent],
  imports: [CommonModule, SharedComponentsModule],
  exports: [StatisticsComponent],
})
export class StatisticsModule {}
