import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel/carousel.component';
import { ChapterInfoComponent } from './chapter-info/chapter-info.component';
import { PhoneComponent } from './phone/phone.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { ExtInfoComponent } from './ext-info/ext-info.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { TranslateModule } from '@ngx-translate/core';
import { RequestCallModalComponent } from './request-call-modal/request-call-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FooterBriefComponent } from './footer-brief/footer-brief.component';

@NgModule({
  declarations: [
    CarouselComponent,
    ChapterInfoComponent,
    PhoneComponent,
    ScrollTopComponent,
    RequestCallComponent,
    RequestCallModalComponent,
    ExtInfoComponent,
    FooterBriefComponent,
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule],
  exports: [
    CarouselComponent,
    ChapterInfoComponent,
    PhoneComponent,
    ScrollTopComponent,
    RequestCallComponent,
    RequestCallModalComponent,
    ExtInfoComponent,
    FooterBriefComponent,
  ],
})
export class SharedComponentsModule {}
