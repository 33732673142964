import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, Type } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICommonContentDto } from '../models/common-content';
import { CacheService } from './cache.service';
import { ITranslation, TranslationHelperService } from './translation-helper.service';
import { UrlsService } from './urls.service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(private _http: HttpClient, private _translationHelperService: TranslationHelperService, private _injector: Injector) {}

  public getContent<T>(chapterName: string, contentClass: Type<T>): Observable<T> {
    const cachedContent = CacheService.cache[chapterName];
    return cachedContent
      ? of(cachedContent)
      : forkJoin({
          translation: this._http.get(UrlsService.getTranslationUrl(chapterName)),
          db: this._http.get(UrlsService.getDbUrl(chapterName)),
        }).pipe(
          map((response: { translation: ITranslation; db: ICommonContentDto }) => {
            this._translationHelperService.setTranslation(response.translation as ITranslation);

            const CONTENT_JSON = response.db;

            const MAPPED_CONTENT = Array.isArray(CONTENT_JSON)
              ? CONTENT_JSON.map((content) => this.#getContentClass(chapterName, contentClass, content))
              : this.#getContentClass(chapterName, contentClass, CONTENT_JSON);

            CacheService.cache[chapterName] = MAPPED_CONTENT;

            return MAPPED_CONTENT;
          })
        );
  }

  #getContentClass<T>(chapterName: string, contentClass: Type<T>, content: ICommonContentDto): T {
    return new contentClass({ id: chapterName, ...content }, this._injector);
  }
}
