import { Injector } from '@angular/core';
import { CommonContent, ICommonContentDto } from '../../../../../models/common-content';
import { StylesService } from '../../../../../services/styles.service';

export interface IGreetingItemDto extends ICommonContentDto {
  icon_color: string;
}

export class GreetingItem extends CommonContent {
  readonly iconColor!: string;

  constructor(options: IGreetingItemDto, protected injector: Injector) {
    super(options, injector);

    this.iconColor = this.injector.get(StylesService).getColor(options.icon_color);
  }
}
