import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgramItem } from '../../models/program-item';

@Component({
  selector: 'schedule-program-item',
  templateUrl: './schedule-program-item.component.html',
  styleUrls: ['./schedule-program-item.component.scss'],
})
export class ScheduleProgramItemComponent {
  @Input() program!: ProgramItem;

  @Output() readonly programClick: EventEmitter<ProgramItem> = new EventEmitter();

  onProgramClick(): void {
    this.programClick.emit(this.program);
  }
}
