import { Injector } from '@angular/core';
import { CommonContent, ICommonContent, ICommonContentDto } from './common-content';

export interface ICommonContentWithImg extends ICommonContent {
  img: string;
}

export abstract class CommonContentWithImg extends CommonContent implements ICommonContentWithImg {
  abstract readonly img: string;

  protected constructor(protected options: ICommonContentDto, protected injector: Injector) {
    super(options, injector);
  }
}
