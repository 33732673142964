import { Injector } from '@angular/core';
import { CommonContent, ICommonContentDto } from '../../../../../models/common-content';
import { CommonContentWithImg } from '../../../../../models/common-content-with-img';

interface ISecretOfSuccessDto extends ICommonContentDto {
  ext_info: ICommonContentDto;
}

export class SecretOfSuccessContent extends CommonContentWithImg {
  readonly img!: string;
  readonly extInfo!: CommonContent;

  constructor(options: ISecretOfSuccessDto, protected injector: Injector) {
    super(options, injector);

    this.img = `../../../assets/img/${options.id}/${options.id}.png`;
    this.extInfo = new CommonContent({ id: `${options.id}.ext_info`, ...options.ext_info }, injector);
  }
}
