import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../models/common-content';
import { CommonContentWithImg } from '../../../models/common-content-with-img';

export class EventItem extends CommonContentWithImg {
  readonly img!: string;
  readonly schedule!: string;

  constructor(options: ICommonContentDto, protected injector: Injector) {
    super(options, injector);

    this.img = `../assets/img/${options.id.replace(/\./g, '/')}.png`;
    this.schedule = `../assets/img/${options.id.replace(/\./g, '/')}_schedule.jpg`;
  }
}
