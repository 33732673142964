import { Injector } from '@angular/core';
import { ParentPageItem } from './parent-page-item';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../models/common-content-with-items';
import { ICommonContentDto } from '../../../models/common-content';

export class ParentsPageContent extends CommonContentWithItems<ParentPageItem> {
  readonly id!: string;
  readonly items!: ParentPageItem[];

  constructor(options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);

    this.id = options.id;
    this.items = options.items.map(
      (item: ICommonContentDto) => new ParentPageItem({ ...item, ...{ id: `${options.id}.${item.id}` } }, this.injector)
    );
  }
}
