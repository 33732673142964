import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContactsService } from '../../services/contacts.service';
import { IContacts } from '../../models/interfaces';
import { Observable } from 'rxjs';

@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsComponent {
  requisitesContent: Observable<IContacts> = this._contactsService.getContent();

  constructor(private _contactsService: ContactsService) {}
}
