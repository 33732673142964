import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../models/common-content';
import { CommonContentWithImg } from '../../../models/common-content-with-img';

export interface IPhotosPageItemDto extends ICommonContentDto {
  count: number;
}

export class PhotosPageContentItem extends CommonContentWithImg {
  readonly id!: string;
  readonly count!: number;
  readonly img!: string;

  constructor(options: IPhotosPageItemDto, protected injector: Injector) {
    super(options, injector);

    this.id = options.id.split('.').pop();
    this.count = options.count || 0;
    this.img = `../../../assets/img/${options.id.replace(/\./g, '/')}.png`;
  }
}
