import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '../components/shared-components.module';
import { LanguageToggleComponent } from './components/language-toggle/language-toggle.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { NavMenuItemComponent } from './components/nav-menu-item/nav-menu-item.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

@NgModule({
  declarations: [NavMenuComponent, NavMenuItemComponent, MobileMenuComponent, LanguageToggleComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [NavMenuComponent],
})
export class NavMenuModule {}
