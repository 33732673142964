import { Injector } from '@angular/core';
import { ICommonContentDto } from '../../../../../models/common-content';
import { CommonContentWithImg } from '../../../../../models/common-content-with-img';

interface IAdvertisementDto extends ICommonContentDto {
  desc_color: string;
}

export class AdvertisementsContent extends CommonContentWithImg {
  readonly img!: string;

  constructor(options: IAdvertisementDto, protected injector: Injector) {
    super(options, injector);

    this.img = `../../assets/img/advrts/${this.options.id}.png`;
  }
}
