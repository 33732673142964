import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContentService } from '../../../../services/content.service';
import { ParentPageItem } from '../../models/parent-page-item';
import { ParentsPageContent } from '../../models/parents-page-content';

@Component({
  selector: 'parents-page',
  templateUrl: './parents-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentsPageComponent {
  intro!: ParentPageItem;
  childhood!: ParentPageItem;
  adult!: ParentPageItem;
  parent!: ParentPageItem;
  outro!: ParentPageItem;

  content$: Observable<ParentsPageContent> = this._contentService
    .getContent<ParentsPageContent>('parents', ParentsPageContent)
    .pipe(
      tap((content: ParentsPageContent) =>
        ['intro', 'childhood', 'adult', 'parent', 'outro'].forEach((prop: string) => (this[prop] = this.#findItem(content, prop)))
      )
    );

  constructor(private _contentService: ContentService) {}

  #findItem(content: ParentsPageContent, suffix: string): ParentPageItem {
    return content.items.find((item: ParentPageItem) => item.id === `${content.id}.${suffix}`);
  }
}
