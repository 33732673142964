import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AboutInfoComponent } from './components/about-info/about-info.component';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';

@NgModule({
  declarations: [AboutInfoComponent],
  imports: [CommonModule, SharedComponentsModule],
  exports: [AboutInfoComponent],
})
export class AboutInfoModule {}
