import { Component, Input } from '@angular/core';
import { GreetingItem } from '../../models/greeting-item';

@Component({
  selector: 'greeting-item',
  templateUrl: './greeting-item.component.html',
  styleUrls: ['./greeting-item.component.scss'],
})
export class GreetingItemComponent {
  @Input() item!: GreetingItem;
}
