import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { HistoryContent } from '../../models/history-content';

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent {
  showExtInfo: boolean = false;

  content$: Observable<HistoryContent> = this._contentService.getContent<HistoryContent>('history', HistoryContent);

  constructor(private _contentService: ContentService) {}
}
