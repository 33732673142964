import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locales } from '../../../../enums/locales.enum';

@Component({
  selector: 'language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageToggleComponent {
  readonly #folder: string = '../../../assets/img/menu/langs/';

  readonly selections: { lang: string; img: string }[] = Object.values(locales).map((lang: string) => ({
    lang,
    img: `${this.#folder}${lang}.png`,
  }));

  constructor(private translateService: TranslateService) {}

  onLanguageChange(lang: string): void {
    this.translateService.use(lang);
  }
}
