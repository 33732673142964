import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { SecretOfSuccessContent } from '../../models/secret-of-success-content';

@Component({
  selector: 'secret-of-success',
  templateUrl: './secret-of-success.component.html',
  styleUrls: ['./secret-of-success.component.scss'],
})
export class SecretOfSuccessComponent {
  showExtInfo: boolean = false;

  content$: Observable<SecretOfSuccessContent> = this._contentService.getContent<SecretOfSuccessContent>('secret_of_success', SecretOfSuccessContent);

  constructor(private _contentService: ContentService) {}
}
