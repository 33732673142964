export enum DIRECTIONS {
  RIGHT,
  LEFT,
}

export enum SWIPE_EVENTS {
  SWIPE_RIGHT = 'swiperight',
  SWIPE_LEFT = 'swipeleft',
}

export enum TRANSITIONS {
  ANIMATED = '0.4s ease-in-out',
  NOT_ANIMATED = 'none',
}
