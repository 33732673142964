import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';
import { GreetingItemComponent } from './components/greeting-item/greeting-item.component';
import { GreetingComponent } from './components/greeting/greeting.component';

@NgModule({
  declarations: [GreetingComponent, GreetingItemComponent],
  imports: [CommonModule, SharedComponentsModule],
  exports: [GreetingComponent],
})
export class GreetingModule {}
