import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ITranslation, TranslationHelperService } from '../../../services/translation-helper.service';
import { IContacts } from '../models/interfaces';
import { CacheService } from '../../../services/cache.service';
import { UrlsService } from '../../../services/urls.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  readonly #chapterName: string = 'contacts';

  constructor(private _http: HttpClient, private _translationHelperService: TranslationHelperService) {}

  getContent(): Observable<IContacts> {
    return CacheService.cache[this.#chapterName]
      ? of(CacheService.cache[this.#chapterName])
      : forkJoin({
          translations: this._http.get(UrlsService.getTranslationUrl(this.#chapterName)),
          contacts: this._http.get(UrlsService.getDbUrl(this.#chapterName)),
        }).pipe(
          map((response: { translations: ITranslation; contacts: IContacts }) => {
            this._translationHelperService.setTranslation(response.translations);
            const CONTACTS = response.contacts;
            CacheService.cache[this.#chapterName] = CONTACTS;

            return CONTACTS;
          }),
          share()
        );
  }
}
