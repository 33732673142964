import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';
import { DiplomasComponent } from './components/diplomas/diplomas.component';

@NgModule({
  declarations: [DiplomasComponent],
  imports: [CommonModule, SharedComponentsModule],
  exports: [DiplomasComponent],
})
export class DiplomasModule {}
