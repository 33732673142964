import { CommonContent, ICommonContent, ICommonContentDto } from './common-content';
import { Injector } from '@angular/core';

export interface ICommonContentWithItems<T> extends ICommonContent {
  items: T[];
}

export interface ICommonContentWithItemsDto<T extends ICommonContentDto = ICommonContentDto> extends ICommonContentDto {
  items: T[];
}

export abstract class CommonContentWithItems<T> extends CommonContent implements ICommonContentWithItems<T> {
  abstract items: T[];

  protected constructor(protected options: ICommonContentWithItemsDto, protected injector: Injector) {
    super(options, injector);
  }
}
