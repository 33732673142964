import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { M576 } from '../../../../consts/window';
import { ContentService } from '../../../../services/content.service';
import { PhotosPageContent } from '../../models/photos-page-content';
import { PhotosPageContentItem } from '../../models/photos-page-content-item';

@Component({
  selector: 'photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent {
  imgsUrl: string = '../../../assets/img/photos/items/';
  showCarousel: boolean = false;
  smallImgs: string[] = [];
  largeImgs: { img: string }[] = [];
  slideIndex: number = 2;

  content$: Observable<PhotosPageContent> = this._contentService.getContent<PhotosPageContent>('photos', PhotosPageContent).pipe(
    tap((content: PhotosPageContent) => {
      const SELECTED_ITEM = content.items.find((item: PhotosPageContentItem) => item.id === this._route.snapshot.params.id);

      for (let i = 0; i < SELECTED_ITEM.count; i++) {
        this.smallImgs.push(`${this.imgsUrl}${SELECTED_ITEM.id}/small/${i}.jpg`);
        this.largeImgs.push({
          img: `${this.imgsUrl}${SELECTED_ITEM.id}/${i}.jpg`,
        });
      }
    })
  );

  constructor(private _contentService: ContentService, private _route: ActivatedRoute) {}

  showLargeImg(index: number): void {
    const windowWidth = Math.min(window.outerWidth, window.innerWidth);
    if (windowWidth > M576) {
      this.slideIndex = index;
      this.showCarousel = true;
    }
  }
}
