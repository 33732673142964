import { Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { M1025, M576, M768 } from '../../../../../../consts/window';
import { CarouselComponent } from '../../../../../../shared/components/carousel/carousel.component';
import { ContentService } from '../../../../../../services/content.service';
import { DiplomasContent } from '../../models/diplomas-content';
import { DiplomasService } from '../../services/diplomas.service';

@Component({
  selector: 'diplomas',
  templateUrl: './diplomas.component.html',
  styleUrls: ['./diplomas.component.scss'],
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class DiplomasComponent {
  @ViewChild('carousel', { static: false })
  readonly carousel: CarouselComponent;

  slidesCount: number = 0;
  smallImgs: { img: string }[] = [];
  largeImgs: { img: string }[] = [];
  showLargeCarousel: boolean = false;
  slideIndex: number = 0;

  content$: Observable<DiplomasContent> = this._contentService.getContent<DiplomasContent>(DiplomasService.CHAPTER_NAME, DiplomasContent).pipe(
    tap((content: DiplomasContent) => {
      for (let i = 0; i < content.diplomasCount; i++) {
        this.smallImgs.push({ img: DiplomasService.getSmallImg(i) });
        this.largeImgs.push({ img: DiplomasService.getImg(i) });
      }

      this.setSlideSCount();
    })
  );

  constructor(private _contentService: ContentService) {}

  onResize(): void {
    this.setSlideSCount();
  }

  setSlideSCount(): void {
    const windowWidth = Math.min(window.outerWidth, window.innerWidth);
    if (windowWidth < M576) {
      this.slidesCount = 2;
    } else if (windowWidth < M768) {
      this.slidesCount = 3;
    } else if (windowWidth < M1025) {
      this.slidesCount = 4;
    } else {
      this.slidesCount = 5;
    }
  }

  showBigCarousel(index: number): void {
    if (Math.min(window.outerWidth, window.innerWidth) >= M768) {
      this.slideIndex = index;
      this.showLargeCarousel = true;
      this.carousel.stopAutoSliding();
    }
  }

  closeCarousel(): void {
    this.showLargeCarousel = false;
    this.carousel.startAutoSliding();
  }
}
