import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { AboutInfoModule } from './modules/about-info/about-info.module';
import { ReasonToChoiseModule } from './modules/reason-to-choise/reason-to-choise.module';
import { HistoryModule } from './modules/history/history.module';
import { DiplomasModule } from './modules/diplomas/diplomas.module';
import { WorkingMethodologyModule } from './modules/working-methodology/working-methodology.module';
import { FeedbacksModule } from './modules/feedbacks/feedbacks.module';

@NgModule({
  declarations: [AboutPageComponent],
  imports: [CommonModule, AboutInfoModule, ReasonToChoiseModule, HistoryModule, DiplomasModule, WorkingMethodologyModule, FeedbacksModule],
  exports: [AboutPageComponent],
})
export class AboutPageModule {}
