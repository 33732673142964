import { Injector } from '@angular/core';
import { CommonContentWithItems, ICommonContentWithItemsDto } from '../../../../../models/common-content-with-items';
import { IStatisticsItemDto, StatisticsItem } from './statistics-item';

export class StatisticsContent extends CommonContentWithItems<StatisticsItem> {
  readonly items!: StatisticsItem[];

  constructor(options: ICommonContentWithItemsDto<IStatisticsItemDto>, protected injector: Injector) {
    super(options, injector);

    this.items = options.items.map(
      (item: IStatisticsItemDto) => new StatisticsItem({ ...item, ...{ id: `${options.id}.items.${item.id}` } }, this.injector)
    );
  }
}
