import { Component, Input } from '@angular/core';
import { ChapterInfo } from '../../../models/chapter-info';

@Component({
  selector: 'chapter-info',
  templateUrl: './chapter-info.component.html',
})
export class ChapterInfoComponent {
  @Input() chapterInfo!: ChapterInfo;
}
