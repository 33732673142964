import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../../../services/content.service';
import { StatisticsContent } from '../../models/statistics-content';

@Component({
  selector: 'statistics',
  templateUrl: './statistics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsComponent {
  content$: Observable<StatisticsContent> = this._contentService.getContent<StatisticsContent>('statistics', StatisticsContent);

  constructor(private _contentService: ContentService) {}
}
