import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateCompiler, TranslateModule } from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { SharedComponentsModule } from '../../../../shared/components/shared-components.module';
import { SecretOfSuccessComponent } from './components/secret-of-success/secret-of-success.component';

@NgModule({
  declarations: [SecretOfSuccessComponent],
  imports: [
    CommonModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
  ],
  exports: [SecretOfSuccessComponent],
})
export class SecretOfSuccessModule {}
