import { Component, Input } from '@angular/core';
import { NavMenuItem } from '../../models/nav-menu-item';

@Component({
  selector: 'nav-menu-item',
  templateUrl: './nav-menu-item.component.html',
  styleUrls: ['./nav-menu-item.component.scss'],
})
export class NavMenuItemComponent {
  @Input() item!: NavMenuItem;
}
